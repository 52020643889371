@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(74, 74, 74,0.8);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.changePhoto2 {
  @apply hidden dark:invert
}

.changePhoto1{
  @apply dark:invert
}
.changePhotos:hover .changePhoto1 {
  @apply hidden
}
.changePhotos:hover .changePhoto2 {
  @apply block
}